import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { timeStamp } from 'console';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { CoreRepository } from 'src/app/core/core.repository';
import { CompanyCodeIds } from 'src/app/core/enums/companyCodeEnums';
import { ActionMessages } from 'src/app/core/enums/emailEnums';
import { EmailDefinitionIds } from 'src/app/core/enums/emailDefinitionEnums';
import { ProgramTableTypeIds } from 'src/app/core/enums/programTableTypeEnums';
import { StatusDefinitionIds, StatusDefinitionShortDesc } from 'src/app/core/enums/statusDefinitionEnums';
import { FinancialAssumptionErrors, AttachmentErrors, ExternalProgramErrors } from 'src/app/core/enums/messageEnums';
import { EmailService } from 'src/app/core/services/email/email.service';
import { EmailOptions } from 'src/app/core/services/email/models/email-options.model';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { User } from 'src/app/core/user/User';
import { DialogWarningMessageComponent, Messages } from 'src/app/dialogs/dialog-warning-message/dialog-warning-message.component';
import { DialogProgramComponent } from 'src/app/programs/dialog-program/dialog-program.component';
import { routeforApprovalComponent } from '../route-for-approval/route-for-approval.component';
import { ProgramCategories ,ProgramCategorySetNames} from 'src/app/core/enums/programCategoryEnums';
import { UrlService } from 'src/app/core/services/url-service.service';


export interface ProgramApproversEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programApproverId: number;
  emailOptions?: any;
}

export interface DoaUsers {
  doaUserId: number;
  appUserId: number;
  userId: string;
  userName: string;
  lastName: string;
  firstName: string;
  email: string;
  approver: boolean;
}

export interface ProgramToProductManagerAssoc {
  id?: number;
  programId: number;
  productManagerId: number;
  openEnrolled: boolean;
  updateBy: string;
}

export interface ProgramManagersEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programManagerId: number;
  emailOptions?: any;
  changeExceptionId?: any;
  changeException: boolean;
}
export interface ProgramManager {
  id: number;
  userName: string;
  email: string;
  lastName: number;
  firstName: string;
  active: boolean;
  roleCode: string;
  role: string;
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-create-program',
  templateUrl: './create-program.component.html',
  styleUrls: ['./create-program.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS
    }
  ]
})

export class CreateProgramComponent implements OnInit, AfterViewInit {
  programForm: FormGroup;
  submitted: boolean = false;
  appUserList: any;
  channelList: any;
  yearList: any;
  businessSegmentList: any;
  programTypeList: any;
  companyCodeList: any;
  consistentNameList: any[];
  filteredconsistentNameList: Observable<any[]>;
  cancelSubject = new Subject();
  prodmgrs: any[] = [];
  prodmgrids: any[] = [];
  productManagerEmails: any[] = [];
  routingApproversEmails: ProgramApproversEmail[] = [];
  programManagersEmail: ProgramManagersEmail[] = [];
  changeExceptionId?: any;
  programManagerAppUserId: number;
  programManagersEmailOptions = new EmailOptions();
  programManagerEmails: any[] = [];
  approversEmails: any[] = [];
  programTableTypeIds = ProgramTableTypeIds;
  emailDefinitionIds = EmailDefinitionIds;
  actionMessages = ActionMessages;
  statusDefinitionIds = StatusDefinitionIds;
  statusDefinitionShortDesc = StatusDefinitionShortDesc;
  emailOptions = new EmailOptions();
  approversEmailOptions = new EmailOptions();
  doaUsers: DoaUsers[] = [];
  programToProductManagerAssoc: ProgramToProductManagerAssoc[] = [];
  parentProgramsList: any[];
  filteredParentProgramsList: Observable<any[]>;
  showParentProgramFA: boolean = false;
  SearchProgram: any;
  businessSegSelected: string[];
  minEndDate: Date;
  maxEndDate: Date;
  minStartDate: Date;
  maxStartDate: Date;
  buttonText: boolean = false;
  showExternalProgramTypeOptions: boolean = false;
  showAccruesUnderAnotherProgramTypeOptions: boolean = false;
  isStandardOrParentTypeProgram: boolean = false;
  selected = -1;
  parentProgCode: string = "";
  parentProgramShortName: string = "";
  parentProgStatus: string;
  parentProgramStatusDefinitionId?: number;
  warningMessages: Messages[] = [];
  financialAssumptionErrors = FinancialAssumptionErrors;
  externalProgramErrors = ExternalProgramErrors;
  saveAndProceed = true;
  externalProgramTypeErrorFound = false;
  isSubmitAnother = false;
  updatedProdmgrids: any[] = [];
  functionalArea: string[];
  isProgramBrevant: boolean = false;
  progCode: any;
  replicateProgram: boolean  = false;
  IsExternalAccruingOrStandardProgram:boolean  = false;
  previousUrl: Observable<string> = this.urlService.previousUrl$;
  previousUrlText: string = "";
  getProgCode: Observable<number> = this.urlService.progCode$;
  programCode: number;
  startDate: Date;
  endDate: Date;
  nextStartDate: Date;
  nextEndDate: Date;
  programCategory: string;
  ProgramCategorySetNames = ProgramCategorySetNames;
  isNonAccruingProgram: boolean = false;
  adminUserName: string;
  programManagerList: ProgramManager[];
  isffCheckAttachmentSize: boolean = false;
  attachmentErrorFound: boolean = false;
  attachmentErrors = AttachmentErrors;

  constructor(private fb: FormBuilder,
    public context: ProgPortalContextService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    public emailService: EmailService,
    private core: CoreRepository,
    private router: Router,
    public app: AppComponent,
    private urlService: UrlService) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    this.minStartDate = new Date(currentYear - 1, currentMonth, 1);
    this.maxStartDate = new Date(currentYear + 2, currentMonth, 1);
  }

  ngOnInit(): void { 
    this.businessSegSelected = [];
    this.prodmgrs = [];
    this.programForm = this.fb.group({
      ProgramCode: ['--'],
      ShortName: ['',
        {
          validators: [Validators.required],
          asyncValidators: [this.programNameValidator()],
          updateOn: 'blur'
        }
      ],
      ConsistentName: [''],
      ProgramDesc: [''],
      TypeDefinition: [''],
      StatusDefinition: [this.statusDefinitionIds.DRAFT, Validators.required],
      Channel: [''],
      AppUser: [this.core.getUsername()],
      ProgramYear: ['', Validators.required],
      CalculatedTotals: [''],
      TotalSales: [''],
      TotalExposure: [''],
      BeginDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      BundleRoute: [false],
      ProgramManager: [this.core.getUserAccountName()],
      BusinessSegments: [this.businessSegSelected],
      CompanyCode: ['', Validators.required],
      FileUpload: [''],
      ExternalAccruingProgramType: [''],
      IsExternalAccruingProgram: [''],
      NonAccruingProgramType: [''],
      NonAccruingProgramTypeName: [''],
      AccruesUnderCheckBox: [''],
      EmailOptions: [''],
      DOAUsers: [[]],
      ProgramToProductManagerAssoc: [[]]
    });
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrlText = previousUrl;
    });
    this.urlService.progCode$.subscribe((getProgCode: number) => {
      this.programCode = getProgCode;
    });
    this.checkFeatures();
  }

  ngAfterViewInit() {
    this.binddropdowns();
    if(this.previousUrlText.includes('review-program')){
      this.prepopulateProgram()
    }
 
  }
  get f() { return this.programForm.controls; }

  onlyFirstDay = (d): boolean => {
    if ( d != null )
    {
      d = d.toDate();
      const date = d.getDate();
      var firstDay = new Date(d.getFullYear(), d.getMonth(), 1).getDate();
      // all other dates except first date of month are disabled.
      return firstDay == date;
    }
  }

  onlyLastDay = (d): boolean => {
    if ( d != null)
    {
      d = d.toDate();
      const date = d.getDate();
      var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
      // all other dates except last date of month are disabled.
      return lastDay == date;
    }
  }

  checkIfProgramNameExists(programName: string): Observable<boolean> {
    let programNameList = [];
    programNameList.push(programName);

    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.context.postdatatoservice("/api/Program/Option/GetIsProgramNameExists", JSON.stringify(programNameList), headers)
      .pipe(map(response => {
        return response;
      }))
  }

  programNameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkIfProgramNameExists(control.value)
        .pipe(
          map(res => {
            return res ? { programNameExists: true } : null;
          })
        );
    };
  }

  programFilter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.parentProgramsList.filter(parentProgramsList => parentProgramsList.shortName.toLowerCase().includes(filterValue) || String(parentProgramsList.code).includes(filterValue));
  }

  consistentNameFilter(value: any): any[] {
    const filterValue = value ? value.toLowerCase() : value;
    return this.consistentNameList.filter(consistentNameList => consistentNameList.toLowerCase().includes(filterValue) || String(consistentNameList.code).includes(filterValue));
  }

  isAdmin(): boolean {
    return this.core.isAdmin;
  }

  onSubmit() {   
    if (this.programForm.controls.NonAccruingProgramTypeName.value) {
      this.programForm.controls.NonAccruingProgramType.setValue(this.parentProgCode);
    }
    this.programForm.controls.AppUser.setValue(this.programForm.controls.ProgramManager.value);
    this.submitted = true;
    if (this.isStandardOrParentTypeProgram && this.f.NonAccruingProgramTypeName.value == '') {
      this.programForm.get('TotalSales').setValidators([Validators.required]);
      this.programForm.get('TotalSales').updateValueAndValidity();
      this.programForm.get('TotalExposure').setValidators([Validators.required]);
      this.programForm.get('TotalExposure').updateValueAndValidity();
      this.programForm.markAllAsTouched();
    } 

    if (this.replicateProgram) {
      var companyCode = this.companyCodeList.filter(c => c.shortDesc == this.programForm.controls.CompanyCode.value);
      if(companyCode !== undefined){
      this.programForm.controls.CompanyCode.setValue(companyCode[0].id);
      }
    }
    if(this.appUserList !== undefined){
    var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
    }

    if (this.programForm.valid && this.saveAndProceed) {
      this.spinner.show();
      this.context.postdatatoservice("/api/Program/Table", this.toFormData(this.programForm.value))
        .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
          if (response !== null) {
            this.progCode = response.entity.code;
            let programId = 0;
            let programType = 0;
            let changeExceptionId = 0;
            programId = (response.entityId !== undefined && response.entityId !== null) ? parseFloat(response.entityId) : programId;

            programType = (response.entity.id != null && response.entity.programId != null) ? this.programTableTypeIds.NonAccruingProgram : (response.entity.id == null && response.entity.externalAccruingProgramId) ? this.programTableTypeIds.ExternalAccruingProgram : this.programTableTypeIds.Program;
            if (programId !== 0 && response.entity.statusDefinition.id === this.statusDefinitionIds.APPROVAL_ROUTING) {
              this.sendEmailNotificationToApprovers(programId, response.entity.code, programType)
            }
            else if (response.entity.statusDefinition.id === this.statusDefinitionIds.DRAFT && this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {

              const headers = new HttpHeaders().set('content-type', 'application/json');
              changeExceptionId = this.changeExceptionId === 0 ? this.changeExceptionId = null : this.changeExceptionId;
              this.buildProgramManagerEmail(this.progCode)
              this.programManagersEmail.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.ADMIN_TO_PROGRAMMGR, programManagerId: this.programManagerAppUserId, emailOptions: JSON.parse(JSON.stringify(this.programManagersEmailOptions)), changeExceptionId: changeExceptionId, changeException: false },);
              if (this.programManagersEmail.length > 0) {
                this.context.postdatatoservice("/api/Email/Table/ProgramManagerEmail", JSON.stringify(this.programManagersEmail), headers)
                  .toPromise().then((response) => {
                    if (response != null) {
                      this.openSuccessSnackBar('Successfully added program', 'x', 3000);
                      this.router.navigate(['programs']);
                      this.prodmgrids = [];
                      this.router.navigate(['programs']);
                      this.programManagersEmail = [];
                    }
                  })
              }
            }
            else {
              this.openSuccessSnackBar('Successfully added program', 'x', 3000);
              this.router.navigate(['programs']);
              this.prodmgrids = [];
              this.router.navigate(['programs']);
            }
            this.spinner.hide();
          }
        },
          msg => { // Error
            this.openErrorSnackBar('Error in adding program', 'x', 3000);
          })
        .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));

    } else { // Error that might occur before submission if validation fails
      this.openErrorSnackBar('Error in adding program', 'x', 3000);
      this.spinner.hide();
    }

  }

  toFormData<T>(formValue: T) {
    const formData = new FormData();
    this.buildFormData(formData, formValue, null);
    const fileUploadData = formValue['FileUpload'];
    if (this.attachmentErrorFound) {        
      for (let i = 0; i < fileUploadData.length; i++) {
        let file = 'FileUpload'
        let fileIndex = file.concat('[', i.toString(), ']');
        formData.delete(fileIndex);
      }
      formData.append('FileUpload','');
    }
    else if (fileUploadData) {
      formData.delete('FileUpload');
      if (fileUploadData && Array.isArray(fileUploadData)) { // check for arrays
        for (const v of fileUploadData) {
          formData.append('FileUpload', v);
        }
      }
    }

    formData.delete('EmailOptions');
    if (this.programForm.controls.EmailOptions.value) {
      formData.append('EmailOptions', JSON.stringify(this.programForm.controls.EmailOptions.value));
    }
    return formData;
  }

  buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !moment.isDate(data) && !moment.isMoment(data)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }

  onSubmitSaveAndReleaseORRouteForApproval() {
    this.submitted = true;
    this.programForm.get('ProgramDesc').setValidators([Validators.required]);
    this.programForm.get('ProgramDesc').updateValueAndValidity();
    this.programForm.get('CompanyCode').setValidators([Validators.required]);
    this.programForm.get('CompanyCode').updateValueAndValidity();
    this.programForm.get('BusinessSegments').setValidators([Validators.required]);
    this.programForm.get('BusinessSegments').updateValueAndValidity();
    this.programForm.get('TypeDefinition').setValidators([Validators.required]);
    this.programForm.get('TypeDefinition').updateValueAndValidity();
    this.programForm.get('Channel').setValidators([Validators.required]);
    this.programForm.get('Channel').updateValueAndValidity();
    if (this.programForm.controls.ExternalAccruingProgramType.value != ProgramCategories._1) {
      this.programForm.get('TotalSales').setValidators([Validators.required]);
      this.programForm.get('TotalSales').updateValueAndValidity();
      this.programForm.get('TotalExposure').setValidators([Validators.required]);
      this.programForm.get('TotalExposure').updateValueAndValidity();
    }
    this.programForm.markAllAsTouched();

    if (this.programForm.controls.ExternalAccruingProgramType.value != "" || this.programForm.controls.NonAccruingProgramTypeName.value != "" || this.programForm.controls.CompanyCode.value == CompanyCodeIds.CANADA) {
      if (!this.programForm.invalid) {    
        this.programForm.controls.StatusDefinition.setValue(this.statusDefinitionIds.APPROVAL_ROUTING);
        this.openRouteforApprovalComponent();
      }
      else {
        this.openErrorSnackBar('Error in adding program', 'x', 3000);
      }
    }
    else {
      if (!this.programForm.invalid) {
        this.openDialog();
      }
    }
  }

  openDialog(): void {
    this.programToProductManagerAssoc = [];
    const dialogRef = this.dialog.open(DialogProgramComponent, {
      data: { prodmgrs: this.prodmgrs, prodmgrids: this.prodmgrids, prodmgremails: this.productManagerEmails, updatedProdmgrids: this.updatedProdmgrids, confirmSubmit: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result['confirmSubmit']) {
          result.updatedProdmgrids.forEach(element => {
            this.programToProductManagerAssoc.push({
              id: null, productManagerId: element, openEnrolled: false, programId: 0, updateBy: this.core.getUsername()
            })
          });
          this.programForm.controls.ProgramToProductManagerAssoc.setValue(this.programToProductManagerAssoc);
          this.productManagerEmails = result.prodmgremails;
          this.programForm.controls.StatusDefinition.setValue(this.statusDefinitionIds.ACCRUALS_PENDING);
          if (this.productManagerEmails.length > 0) {
            this.buildProductManagerEmail(this.progCode);
          }
          this.onSubmit();
        }
      }
    });
  }

  async prepopulateProgram() {
    this.spinner.show();
    this.context.getdatafromService(`/api/Program/Option/${this.programCode}`).toPromise().then((response) => {
      if (response != null) {

        var companyCode = this.companyCodeList.filter(c => c.id == response.companyCode);
        var companyCodeId = companyCode[0].id;
        this.context.getdatafromService('/api/Administration/List/ProgramManager/' + companyCodeId).toPromise().then((responseById) => {
          if (response != null) {
            this.programManagerList = responseById;
            this.replicateProgram = true;
            this.programForm.controls.ShortName.markAsTouched();
            this.startDate = new Date(response.beginDate);
            this.endDate = new Date(response.endDate);
            this.nextStartDate = new Date(this.startDate.getFullYear() + 1, this.startDate.getMonth(), this.startDate.getDate());
            this.nextEndDate = new Date(this.endDate.getFullYear() + 1, this.endDate.getMonth(), this.endDate.getDate());
            var lastDay = new Date(this.endDate.getFullYear() + 1, this.endDate.getMonth() + 1, 0).getDate();
            this.minEndDate = new Date(this.startDate.getFullYear() + 1, this.startDate.getMonth(), this.startDate.getDate());
            this.maxEndDate = new Date(this.startDate.getFullYear() + 2, this.startDate.getMonth() - 1, lastDay);
            this.minStartDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1);
            this.maxStartDate = new Date(this.startDate.getFullYear() + 3, this.startDate.getMonth(), 1);
            this.buttonText = (response.programTableType == this.programTableTypeIds.NonAccruingProgram || response.programTableType == this.programTableTypeIds.ExternalAccruingProgram) ? true : false;
            this.programForm.controls.ShortName.setValue(response.shortName);
            this.programForm.controls.ConsistentName.setValue(response.consistentName);
            this.programForm.controls.ProgramDesc.setValue(response.programDesc);
            this.programForm.controls.CompanyCode.setValue(companyCode[0].shortDesc);
            this.programForm.controls.ProgramManager.setValue(this.core.getUserAccountName());
            if (this.isAdmin()) {
              this.programForm.controls.AppUser.setValue(response.appUser.userName);
              this.programManagerAppUserId = response.appUser.id;
            }
            this.programForm.controls.CalculatedTotals.setValue(true);
            this.IsExternalAccruingOrStandardProgram = true;
            this.programForm.controls.BusinessSegments.setValue(response.businessSegments);
            this.programForm.controls.TypeDefinition.setValue(response.typeDefinition.id);
            this.programForm.controls.Channel.setValue(response.channel.id);
            this.programForm.controls.BeginDate.setValue(moment(new Date(this.nextStartDate)));
            this.programForm.controls.EndDate.setValue(moment(new Date(this.nextEndDate)));
            this.programForm.controls.ProgramYear.setValue(response.programYear.id + 1);
            this.programForm.controls.ExternalAccruingProgramType.setValue(response.externalAccruingProgramType);
            this.programForm.controls.NonAccruingProgramType.setValue(response.nonAccruingProgramType);
            this.programForm.controls.TotalSales.setValue(0);
            this.programForm.controls.TotalExposure.setValue(0);

            switch (response.programTableType) {
              case this.programTableTypeIds.Program:
                this.programCategory = this.ProgramCategorySetNames.StandardProgram;
                break;
              case this.programTableTypeIds.ExternalAccruingProgram:
                this.programForm.controls.IsExternalAccruingProgram.setValue(1);
                this.showExternalProgramTypeOptions = true;
                this.programCategory = this.ProgramCategorySetNames[response.externalAccruingProgramType];
                break;
            }
            if (response.programProductManager) {
              this.prodmgrs = response.programProductManager.map(x => x.name);
              this.prodmgrids = response.programProductManager.map(x => x.id);
              this.productManagerEmails = response.programProductManager.map(x => x.email);
              this.updatedProdmgrids = response.programProductManager.map(x => x.id);
            }
          }
        });    
      }
      this.spinner.hide();
    });
  }

  binddropdowns() {
    this.spinner.show();
    this.context.getdatafromService('/api/Program/Filter').toPromise().then((response) => {
      if (response != null) {
        this.consistentNameList = response.consistentName;
        this.filteredconsistentNameList = this.programForm.controls.ConsistentName.valueChanges.pipe(
          startWith(''),
          map(value => this.consistentNameFilter(value))
        );
      }
    });

    this.context.getdatafromService('/api/Program/Option/FunctionalArea').toPromise().then((response) => {
      if (response != null) {
        this.functionalArea = this.core.getFunctionalArea();
        this.companyCodeList = response.filter(r => this.functionalArea.find(f => f == r.shortDesc));
      }
    });
    this.context.getdatafromService('/api/Program/Option/Channel').toPromise().then((response) => {
      if (response != null) {
        this.channelList = response;
      }
    });
    this.context.getdatafromService('/api/Program/Option/TypeDefinition').toPromise().then((response) => {
      if (response != null) {
        this.programTypeList = response;
      }
    });
    this.context.getdatafromService('/api/Program/Option/BusinessSegment').toPromise().then((response) => {
      if (response != null) {
        this.businessSegmentList = response;
      }
    });
    this.context.getdatafromService('/api/Program/Option/GetAllParentPrograms').toPromise().then((response) => {
      if (response != null) {
        this.parentProgramsList = response;
        this.spinner.hide();
      }
    });
    this.context.getdatafromService('/api/Program/Option/ProgramYear').toPromise().then((response) => {
      if (response != null) {
        this.yearList = response;
      }
    });
      this.context.getdatafromService('/api/Program/Option/AppUser').toPromise().then((response) => {
        if (response != null) {
          this.appUserList = response;
        }
      });
  }

  buildProductManagerEmail(progCode) {
    if (progCode === undefined || progCode === '') {
      progCode = 'NewProgramCode';
    }

    var description = "A program manager has released a program <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + progCode + "</b>) so you can add your accruals.";
    var nextSteps = "<li>Click on the link below to add, or edit your products and accruals.</li><li>Release the changes to the Program Manager.</li>";
    var buttonLinkUrl = "accruals";
    var buttonText = "Go to Program Accruals";
    var emailSubject = "New program needs Accruals added!";
    var toAddress = this.productManagerEmails;
    var ccAddress;
      var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
      ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
      if (appUser.length > 0) {
        if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
          ccAddress.push(this.core.getUsername());
          this.getUserName(this.core.getUserAccountName())
          description="<b>"+this.adminUserName+" (ADMIN)</b> has released a program <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + progCode + "</b>) so you can add your accruals.";
        }
      }
    this.emailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    this.programForm.controls.EmailOptions.setValue(this.emailOptions);
  }

  buildProgramManagerEmail(progCode) {
    if (progCode === undefined || progCode === '') {
      progCode = 'NewProgramCode';
    }
    this.getUserName(this.core.getUserAccountName())
    var description = "<b>"+ this.adminUserName + " (ADMIN)</b> has saved a draft of a new program  <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + progCode + "</b>) so you can edit the new program as needed, or take the appropriate action.";
    var nextSteps = "<li>Click on the link below to Edit the new program and take any action that is needed.</li>";
    var buttonLinkUrl = 'programs/edit-program/' + progCode;
    var buttonText = "Go to Edit page";
    var emailSubject = "ADMIN has saved a draft of a new program.";
    var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
    var toAddress = [appUser[0].email];
    var ccAddress = [this.core.getUsername()];
    this.programManagersEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
  }

  buildApproversEmail(approverEmail, programCode) {
    this.approversEmails = [];
    this.approversEmails.push(approverEmail);
    var parentProgram = "";
    var description = "A program manager has released a program <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    var nextSteps = "<li>Click on the link below to review the program.</li><li>Post comments to the Program Manager for discussion.</li><li>Click Approve button to continue the approval process.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;
    var buttonText = "Go to Review page";
    var emailSubject = "New program needs your Approval!";
    var toAddress = this.approversEmails;
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
        this.getUserName(this.core.getUserAccountName())
        description = "<b>" + this.adminUserName + " (ADMIN)</b> has released a program <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
      }
    }

     if(this.parentProgCode.toString().length > 0){
      parentProgram =this.parentProgramShortName + " (" +  this.parentProgCode + ")";

      this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress,null,null,parentProgram);
    }
    else{
      this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    }
  }

  getUserName(userName) {
    var splitUserName = userName.split(',')
    this.adminUserName = splitUserName.slice(1, 2) + " " + splitUserName.slice(0, 1)
  }

  createApproversEmailList(programId, programCode, programType, isApprover) {
    this.approversEmails = [];
    this.routingApproversEmails = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.context.getdatafromService('/api/Program/Routing/Details/' + programId + '/' + programType + '/' + isApprover + '/' + false + '/' + false + '/' + this.progCode.toString()).toPromise().then((response) => {
      if (response != null) {
        this.doaUsers = response;
        if (this.doaUsers.length > 0) {
          this.doaUsers.forEach(du => {
            this.buildApproversEmail(du.email, programCode);
            this.routingApproversEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.PROGRAMMGR_TO_DOAAPPROVER, programApproverId: du.appUserId, emailOptions: JSON.parse(JSON.stringify(this.approversEmailOptions)) },);
          })
          if (this.routingApproversEmails.length > 0) {
            const headers = new HttpHeaders().set('content-type', 'application/json');

            this.context.postdatatoservice("/api/Email/Table/ApproverEmail", JSON.stringify(this.routingApproversEmails), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.openSuccessSnackBar('Successfully added program', 'x', 3000);
                  this.routingApproversEmails = [];
                  this.router.navigate(['programs']);
                  this.prodmgrids = [];
                  this.router.navigate(['programs']);
                }
              });
          }
        }
      }
    });
  }

  sendEmailNotificationToApprovers(programId, programCode, programType) {
    this.createApproversEmailList(programId, programCode, programType, true);
  }

  public closedailog() {
    this.dialog.closeAll();
  }

  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  }

  externallyAccruingProgramTypeOptions(checked: boolean) {
    this.showExternalProgramTypeOptions = checked;
    this.showAccruesUnderAnotherProgramTypeOptions = false;
    this.programForm.controls.NonAccruingProgramType.setValue('');
    this.programForm.controls.NonAccruingProgramTypeName.setValue('');
    this.parentProgCode = "";
    this.parentProgramStatusDefinitionId = 0 ;
    if (!checked) {
      this.programForm.controls.ExternalAccruingProgramType.setValue('');
    }
    this.checkStandardUsOrParentTypeProgram();
  }

  externalAccruingOptionsChanged($event) {
    this.programForm.controls.TotalExposure.setValue('');
    this.programForm.controls.TotalSales.setValue('');
    this.isStandardOrParentTypeProgram = false;
    this.buttonText = (this.programForm.controls.ExternalAccruingProgramType.value != "") ? true : false;
    if (this.programForm.controls.ExternalAccruingProgramType.value == ProgramCategories._1) {
      this.programForm.get('TotalSales').setValidators([]);
      this.programForm.get('TotalSales').updateValueAndValidity();
      this.programForm.get('TotalExposure').setValidators([]);
      this.programForm.get('TotalExposure').updateValueAndValidity();
    }
  }

  AccruesUnderProgramTypeOptions(checked: boolean) {
    this.showAccruesUnderAnotherProgramTypeOptions = checked;
    this.showExternalProgramTypeOptions = false;
    this.filteredParentProgramsList = this.programForm.controls.NonAccruingProgramTypeName.valueChanges.pipe(
      startWith(''),
      map(value => this.programFilter(value))
    );
    this.buttonText =false;
    this.programForm.controls.ExternalAccruingProgramType.setValue('');
    if (!checked) {
      this.parentProgramStatusDefinitionId = 0;
      this.programForm.controls.NonAccruingProgramTypeName.setValue('');
      this.programForm.controls.NonAccruingProgramType.setValue('');
      this.showParentProgramFA = false;
    }
    this.checkStandardUsOrParentTypeProgram();
  }

  onParentProgramSelectionChanged() {
    this.buttonText = this.programForm.controls.NonAccruingProgramTypeName.value ? true : false;
    this.showParentProgramFA = this.programForm.controls.NonAccruingProgramTypeName.value ? true : false;
    this.checkStandardUsOrParentTypeProgram();
    var parentProgram = this.parentProgramsList.filter(parentProgram => parentProgram.shortName === this.programForm.controls.NonAccruingProgramTypeName.value);
    if (parentProgram.length > 0) {
      this.programForm.controls.CompanyCode.setValue(parentProgram[0].functionalAreaId);
      this.businessSegSelected = [];
      parentProgram[0].businessSegmentId.forEach(p => this.businessSegSelected.push(p));
      this.programForm.controls.BusinessSegments.setValue(this.businessSegSelected);
      if (parentProgram[0].typeDefinitionId != null) {
        this.programForm.controls.TypeDefinition.setValue(parentProgram[0].typeDefinitionId);
      }
      if (parentProgram[0].channelId != null) {
        this.programForm.controls.Channel.setValue(parentProgram[0].channelId);
      }
      this.programForm.controls.ProgramYear.setValue(parentProgram[0].programYearId);
      this.programForm.controls.BeginDate.setValue(moment(new Date(parentProgram[0].beginDate)));
      this.programForm.controls.EndDate.setValue(moment(new Date(parentProgram[0].endDate)));
      this.programForm.controls.TotalExposure.setValue(Math.trunc(parentProgram[0].totalExposure));
      this.programForm.controls.TotalSales.setValue(Math.trunc(parentProgram[0].totalSales));
      this.parentProgStatus = this.statusDefinitionShortDesc['_' + parentProgram[0].statusDefinitionId];
      this.parentProgCode = parentProgram[0].changeExceptionCode !== '' && parentProgram[0].changeExceptionCode !== null ? parentProgram[0].changeExceptionCode : parentProgram[0].code;
      this.parentProgramShortName = parentProgram[0].shortName;
      this.parentProgramStatusDefinitionId = parentProgram[0].statusDefinitionId;
    } 
  }

   onCompanyCodeChange() {
    this.buttonText = (this.programForm.controls.CompanyCode.value == CompanyCodeIds.CANADA || this.programForm.controls.ExternalAccruingProgramType.value != "" || this.programForm.controls.NonAccruingProgramTypeName.value != "") ? true : false;
    this.checkStandardUsOrParentTypeProgram();
    if (this.programForm.controls.CompanyCode.value == CompanyCodeIds.Brevant) {
      this.selected = 1;
      this.programForm.controls.IsExternalAccruingProgram.disable();
      this.programForm.controls.ExternalAccruingProgramType.disable();
      this.programForm.controls.AccruesUnderCheckBox.disable();
      this.isProgramBrevant = true;
      this.programForm.controls.ExternalAccruingProgramType.setValue(ProgramCategories._2);
      this.externallyAccruingProgramTypeOptions(true);
      this.externalAccruingOptionsChanged(event);
    } else if (this.programForm.controls.CompanyCode.value != CompanyCodeIds.Brevant && this.isProgramBrevant == true) {
      this.selected = 0;
      this.isProgramBrevant = false;
      this.programForm.controls.IsExternalAccruingProgram.enable();
      this.programForm.controls.ExternalAccruingProgramType.enable();
      this.programForm.controls.AccruesUnderCheckBox.enable();
      this.programForm.controls.ExternalAccruingProgramType.setValue('');
      this.externallyAccruingProgramTypeOptions(false);
    }
  }

  checkStandardUsOrParentTypeProgram() {
    //A program is not US standard or Non accruing											  
    if (this.programForm.controls.IsExternalAccruingProgram.value) {
      this.programForm.controls.TotalExposure.setValue('');
      this.programForm.controls.TotalSales.setValue('');
      this.isStandardOrParentTypeProgram = false;
    } else if (this.programForm.controls.CompanyCode.value != CompanyCodeIds.CANADA &&
      this.programForm.controls.CompanyCode.value != "" &&
      this.programForm.controls.ExternalAccruingProgramType.value == "" &&
      (this.programForm.controls.NonAccruingProgramTypeName.value == "" ||
        this.programForm.controls.NonAccruingProgramTypeName.value == undefined)) {
      this.programForm.controls.TotalExposure.setValue(0);
      this.programForm.controls.TotalSales.setValue(0);
      this.isStandardOrParentTypeProgram = true;
      this.programForm.controls.CalculatedTotals.setValue(true);
    } else if (this.programForm.controls.NonAccruingProgramTypeName.value == "" || this.programForm.controls.NonAccruingProgramTypeName.value == undefined) {
      this.programForm.controls.TotalExposure.setValue('');
      this.programForm.controls.TotalSales.setValue('');
      this.isStandardOrParentTypeProgram = false;
    } else if (this.programForm.controls.NonAccruingProgramTypeName.value != "" || this.programForm.controls.NonAccruingProgramTypeName.value != undefined) {
      this.isStandardOrParentTypeProgram = true;
    }
    this.buttonText = (this.programForm.controls.CompanyCode.value == CompanyCodeIds.CANADA || this.programForm.controls.ExternalAccruingProgramType.value != "" || (this.programForm.controls.NonAccruingProgramTypeName.value != undefined && this.programForm.controls.NonAccruingProgramTypeName.value != "") ) ? true : false;
  }

  formStandardProgramTotalsChange() {
    this.programForm.controls.CalculatedTotals.setValue(true);
    const TotalExposure = parseInt(this.f.TotalExposure.value.split(',').join(''));
    const TotalSales = parseInt(this.f.TotalSales.value.split(',').join(''));
    if (TotalExposure > 0 || TotalSales > 0){
      this.programForm.controls.CalculatedTotals.setValue(false);
    }
  }

  restrictChar(event: KeyboardEvent) {
    return String.fromCharCode(event.charCode).match(/[^0-9]/g) === null;
  }

  startDateChange() {
    const d = this.programForm.controls.BeginDate.value.toDate() ;
    const date = d.getDate() + 1;
    const year = d.getFullYear();
    const month = d.getMonth();
    var lastDay = new Date(year + 1, month, 0).getDate();
    this.minEndDate = new Date(year, month + 1, 0);
    this.maxEndDate = new Date(year + 1, month, 0);    
  }

  openRouteforApprovalComponent(): void {
    if (this.replicateProgram) {
      var code = this.programForm.controls.CompanyCode.value == "CANADA" ? "CA" : "US";
    }
    else {
      var code = this.programForm.controls.CompanyCode.value == CompanyCodeIds.CANADA ? "CA" : "US";
    }    

    const totalExposure = this.programForm.controls.TotalExposure.value == "" ? 0 : this.programForm.controls.TotalExposure.value;

    const dialogrouteforApprovalComponentRef = this.dialog.open(routeforApprovalComponent, {
      height: '900px',
      width: '500px',
      disableClose: true,
      data: { totalExposure: totalExposure, companyCode: code, parentProgCode: this.parentProgCode, bindParentDOR: this.parentProgStatus }
    });
    dialogrouteforApprovalComponentRef.afterClosed().subscribe(result => {
      if (result) {
        this.programForm.controls.DOAUsers.setValue(result);
        this.onSubmit();
      } else {
        this.programForm.controls.StatusDefinition.setValue(this.statusDefinitionIds.DRAFT);
      }
    });
  }

  get ParentProgStatus() {
    return this.parentProgramStatusDefinitionId === this.statusDefinitionIds.APPROVAL_ROUTING || this.parentProgramStatusDefinitionId === this.statusDefinitionIds.APPROVED;
  }

  canEditProgram(): boolean {
    return this.core.canEditProgram;
  }

  gotoParent() {
    const status = this.parentProgStatus;
    const programCode = this.parentProgCode;
    let url = "";
    if ((this.parentProgramStatusDefinitionId === this.statusDefinitionIds.DRAFT || this.parentProgramStatusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING || this.parentProgramStatusDefinitionId === this.statusDefinitionIds.ACCRUALS_COMPLETED) && this.canEditProgram()) {
      url = this.router.serializeUrl(
        this.router.createUrlTree(['programs/edit-program/' + programCode.toString()])
      );
    } else {
      url = this.router.serializeUrl(
        this.router.createUrlTree(['programs/review-program/' + programCode.toString()])
      );
    }
    window.open(url, '_blank');
  }
  validateRouteForApprovalSaveRelease(): boolean {
    return !(this.parentProgramStatusDefinitionId != 0 && (this.parentProgramStatusDefinitionId == StatusDefinitionIds.ACCRUALS_PENDING || this.parentProgramStatusDefinitionId == StatusDefinitionIds.ACCRUALS_COMPLETED || this.parentProgramStatusDefinitionId == StatusDefinitionIds.DRAFT));
  }
  validateTotalExposure_OnSubmitAnother() {
    this.isSubmitAnother = true;
    this.validateProgram();
    }

  validateProgram() {		
    this.validateExternalProgram();
    if (this.isffCheckAttachmentSize) {
      this.checkAttachment();
    }
    if (!this.externalProgramTypeErrorFound && !this.attachmentErrorFound) {
      this.validateTotalExposure();
    } 								 										   
  }

  checkAttachment(){
    this.attachmentErrorFound = false;
    var filesUpload = this.programForm.controls.FileUpload.value;
    var sizeOfUpload = 0;
    if (filesUpload && Array.isArray(filesUpload)) {
      for (const v of filesUpload) {        
        sizeOfUpload = sizeOfUpload + v.size; 
      }     
      if (sizeOfUpload > 14500000) {
        if (this.isSubmitAnother)
          {
            this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.attachmentErrors._2, error: true });
          } else {
            this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.attachmentErrors._3, error: true });
          }
        this.attachmentErrorFound = true;
        this.saveAndProceed = false;
      }
      if (this.warningMessages.length > 0) {
        this.openWarningMessageDialog('Save without attachments');
        this.warningMessages = [];
      }    
    }
  }

  validateTotalExposure() {
    this.saveAndProceed = true;
    const TotalExposure = parseInt(this.f.TotalExposure.value.split(',').join(''));
    const TotalSales = parseInt(this.f.TotalSales.value.split(',').join(''));
    if (TotalExposure >= TotalSales && ((this.f.TotalExposure.value != "0" && this.f.TotalSales.value != "0") ||(this.f.TotalExposure.value != "0" && this.f.TotalSales.value == "0") )) {
      this.warningMessages.push({ messageId: 1, message: this.financialAssumptionErrors._1, error: true });
      this.saveAndProceed = false;
    }
    if (this.f.TotalExposure.value === "0" && this.f.TotalSales.value === "0") {
      this.warningMessages.push({ messageId: 1, message: this.financialAssumptionErrors._2, error: false });
    }
    if (this.warningMessages.length > 0) {
      this.openWarningMessageDialog('Save');
    } else if (this.isSubmitAnother) {
      this.isSubmitAnother = false;
      this.onSubmitSaveAndReleaseORRouteForApproval();
    } else {
      this.onSubmit();
    } 
  }

  validateExternalProgram() {
    this.saveAndProceed = true;
    this.externalProgramTypeErrorFound = false;
    if (this.programForm.controls.IsExternalAccruingProgram.value && this.programForm.controls.ExternalAccruingProgramType.value == "") {
      this.warningMessages.push({ messageId: 1, message: this.externalProgramErrors._1, error: true });
      this.saveAndProceed = false;
      this.externalProgramTypeErrorFound = true;
    }
    if (this.warningMessages.length > 0) {
      this.openWarningMessageDialog('Save');
    }
  }

  openWarningMessageDialog(dataContinueText): void {
    const dialogRef = this.dialog.open(DialogWarningMessageComponent, {
      disableClose: true,
      height: '340px',
      width: '700px',
      data: { dataMessage: this.warningMessages, dataContinueText: dataContinueText, dataConfirm: this.saveAndProceed, dataShowErrorResolveMessage: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.saveAndProceed = result['dataConfirm']
        if (this.warningMessages.some(element => element.error)) {
          this.saveAndProceed = false;
        }
        else if (this.isSubmitAnother && this.saveAndProceed) {
          this.onSubmitSaveAndReleaseORRouteForApproval();
        } else
          if (this.saveAndProceed) {
            this.onSubmit();
          }
        this.warningMessages = [];
        this.isSubmitAnother = false;
      }
    });
  }
  async checkFeatures() {
    await this.context
      .getdatafromService('/api/FeatureFlag')
      .toPromise()
      .then((response) => {
        const data = response;
        this.isffCheckAttachmentSize = data.filter(x => x.featureFlagName === "622954_CheckAttachmentSize")[0].enabled;
      });
  }
}
